
//
//profile.scss
//
.profile{
  .online-circle{
    position: absolute;
    right: 0;
    top: 116px;
    left: 70px;
  }
  ul li{
    a{
      span{
        border: 1px dotted $success;
        border-radius: 50%;
        background-color: rgba($success,.15);
        display: inline-block;
        color: $success;
        line-height: 36px;
      }
    }
  }
}
.language-skill li {
  margin-top: 8px;
  border: 1px solid rgba($success,.15);
  border-radius: 5px;
  padding: 0 8px;
  background-color: rgba($success,.15);
  color: $success;
  font-size: 13px;
}
.peity-data {
  position: absolute;
  top: 35px;
  right: 35px;
}

// Activity

.activity {
  position: relative;
  border-left: 1px solid $gray-100;
  margin-left: 16px;
  .item-info {
    margin-left: 30px;
    margin-bottom: 30px;
    h5{
      font-weight: 400;
      font-size: 15px;
    }
  }
  .img-activity,
  i{
    border: 1px solid $gray-100;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 34px;
    border-radius: 50%;
    position: absolute;
    left: -18px;
    color: $primary;
    background-color: $gray-100;
    font-size: 20px;
    box-shadow: 0px 0px 0px 0.5px  $gray-100;
  }
}

// Tab

.custom-tab.tab-profile{
  .nav-tabs{
    .nav-link{
      padding: 8px 14px;
    }
  }
  .nav-link.active {
    background-color: transparent;
  }  
}
#settings .dropify-wrapper{
  width: 14%;
  height: 164px;
  margin-bottom: 30px;
}

// Post Popup

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}
.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}
// Responsiv

@media (max-width: 767px) {
  #settings .dropify-wrapper{
    width: auto;
  }
  .profile{
    #lineChart{
      height: auto !important;
    }
  }
}
